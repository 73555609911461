<template>
  <div class="j-mshe-container user-container">
    <v-header v-if="$route.path !== `${langPath}/user/coupon`" />
    <t-and-c />
    <keep-alive
      :include="keepAliveList"
      :max="3"
    >
      <router-view />
    </keep-alive>

    <OrderMaskDialog />
    <!-- loading -->
    <UserLoading />
    <!-- 信息提示 -->
    <UserMsgModal />
    <UserConfirmModal />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { isUnDef, asyncLoadFile } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
// 原app的index.js的引用
import './analysis'
import { handlePrefetchResource } from './prefetch'
import asyncStreamCenter from './service/asyncStreamCenter'
import UserInfoManager from 'public/src/services/UserInfoManager'
// import { unstable_scheduleCallback, unstable_LowPriority } from 'scheduler'
asyncStreamCenter.init({ scene: 'user-container' })

const { PUBLIC_CDN, WEB_CLIENT } = gbCommonInfo

// const triggerEffect = (fn) => {
//   unstable_scheduleCallback(unstable_LowPriority, () => {
//     fn()
//   })
// }

export default {
  name: 'PageContainer',
  components: {
    // vHeader,
    vHeader: () => import( /* @vite-ignore */ '../components/header.vue'),
    // TAndC: () => import(/* @vite-ignore */ 'public/src/pages/components/coupon/mall/tAndc.vue'),
    // OrderMaskDialog: () => import( /* webpackChunkName: "order-mask-dialog" */ /* @vite-ignore */ './components/OrderMaskDialog.vue'),
    // UserMsgModal: () => import( /* webpackChunkName: "user-msg-modal" */ /* @vite-ignore */ './components/UserMsgModal.vue'),
    // UserLoading: () => import( /* webpackChunkName: "user-loading" */ /* @vite-ignore */ './components/UserLoading.vue'),
    // UserConfirmModal: () => import( /* webpackChunkName: "user-confirm-modal" */ /* @vite-ignore */ './components/UserConfirmModal.vue'),
    // vHeader: () => asyncStreamCenter.register(() => import( /* @vite-ignore */ '../components/header.vue'), { scene: 'user-container' }),
    TAndC: () => asyncStreamCenter.register(() => import(/* @vite-ignore */ 'public/src/pages/components/coupon/mall/tAndc.vue'), { scene: 'user-container' }),
    OrderMaskDialog: () => asyncStreamCenter.register(() => import( /* webpackChunkName: "order-mask-dialog" */ /* @vite-ignore */ './components/OrderMaskDialog.vue'), { scene: 'user-container' }),
    UserMsgModal: () => asyncStreamCenter.register(() => import( /* webpackChunkName: "user-msg-modal" */ /* @vite-ignore */ './components/UserMsgModal.vue'), { scene: 'user-container' }),
    UserLoading: () => asyncStreamCenter.register(() => import( /* webpackChunkName: "user-loading" */ /* @vite-ignore */ './components/UserLoading.vue'), { scene: 'user-container' }),
    UserConfirmModal: () => asyncStreamCenter.register(() => import( /* webpackChunkName: "user-confirm-modal" */ /* @vite-ignore */ './components/UserConfirmModal.vue'), { scene: 'user-container' }),
  },
  beforeRouteLeave (to, from, next) {
    // 关闭快速加车弹窗
    // this.closeQuickAdd()
    if (document?.getElementById('app')) {
      document.getElementById('app').classList.remove('user-center-height');
    }
    if (document?.getElementById('in-app')) {
      document.getElementById('in-app').classList.remove('user-center-height');
    }
    requestAnimationFrame(() => {
      next()
    })
  },
  beforeRouteEnter (to, from, next) {
    if (document?.getElementById('app')) {
      document.getElementById('app').classList.add('user-center-height');
    }
    if (document?.getElementById('in-app')) {
      document.getElementById('in-app').classList.add('user-center-height');
    }
    next(vm => {
      if (WEB_CLIENT == 'romwe') {
        vm.$store.commit('changeSeo', { pageTitle: 'My Account | ROMWE', pageDescription: 'My Account | ROMWE', meta_keywords: 'My Account | ROMWE' }, { root: true })
      }
    })
  },
  data () {
    return {
      keepAliveList: [
        'support',
        'UserGiftcardOrdersList',
        'WishlistGroupName',
        'SharGroupName',
        'WishlistListName',
        'UserOrdersList'
      ], // 缓存组件name集合
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.pageType == 'userPage') {
        // hanldeRouteChange.call(this, to)
        this.changeHeaderNav({ title: '' })
        if (to.meta && to.meta.navTitle) {
          this.changeHeaderNav({
            title: to.meta.navTitle,
            fromName: from.name
          })
        }
        if (document?.getElementById('app')) {
          document.getElementById('app').classList.add('user-center-height');
        }
        if (document?.getElementById('in-app')) {
          document.getElementById('in-app').classList.add('user-center-height');
        }
      }
    },
    // 'blackMask.showMask'(v) {
    //   if (v && this.blackMask?.type === 'addressError') {
    //     daEventCenter.triggerNotice({ daId: '1-11-1-98' })
    //   }
    // }
  },
  created () {
    this.handlePageTitleChange()
    appEventCenter.$on('initUserInfo', () => {
      this.getUserInfo()
      this.fetchCartSum()
      this.refreshCartTagTips()
    })
  },
  mounted () {
    appEventCenter.$emit('homeCccxCouponUpdate')
    /* 预取 */
    handlePrefetchResource(this.$el)
    this.fetchUseLanguage()
    // this.firstLoadFn()
    this.changeUserCenterHeight()
  },
  activated () {
    this.handlePageTitleChange()
    this.fetchUseLanguage()
    this.getUserInfo()
    this.fetchCartSum(true)
    this.changeUserCenterHeight()
  },
  methods: {
    // ...mapMutations('orderDetail', ['changePageStatus']),
    ...mapMutations('root', [
      'changeHeaderNav',
      // 'changeRootStatus',
      'assignRootState',
      // 'changeBlackMaskStatus'
    ]),
    changeUserCenterHeight () {
      this.$nextTick(() => {
        const tabListUser = document?.querySelector('.j-index-tab-list-user');
        if (tabListUser) {
          tabListUser.classList.add('title-active');
          Array.from(tabListUser.parentNode.children).forEach(child => {
            if (child !== tabListUser) {
              child.classList.remove('title-active');
            }
          });
        }
        if (document?.getElementById('app')) {
          document.getElementById('app').classList.add('user-center-height');
        }
        if (document?.getElementById('in-app')) {
          document.getElementById('in-app').classList.add('user-center-height');
        }
      })
    },
    handlePageTitleChange() {
      const route = this.$route
      this.changeHeaderNav({ title: '' })
      if (route.meta && route.meta.navTitle) {
        this.changeHeaderNav({
          title: route.meta.navTitle,
          fromName: ''
        })
      }

      if (!isUnDef(route.meta && route.meta.isHideBack)) {
        this.changeHeaderNav({
          isHideBack: !!route.meta.isHideBack
        })
      }
    },
    // ...mapMutations('orderReturn', ['scrollToElement']),
    async getUserInfo () {

      const userInfo = await UserInfoManager.get({ key: 'UserInfo', actionType: 'getUserInfo' }) || {}

      this.assignRootState({
        userInfo
      })
      window.gbUserInfo = userInfo
    },
    fetchCartSum (noChangeRoot) {
      if(this.$route?.name === 'cart') return

      schttp({
        method: 'POST',
        url: '/api/cart/getNum/get'
      }).then(json => {
        if (json.cartlists) {
          if(!noChangeRoot) {
            let cartInfo = {
              sum: json.cartlists.cartSumQuantity || 0,
              carts: json.cartlists.carts || [],
              info: json.cartlists || {},
              language: json.cartLanguage
            }
            window._gb_app_?.$store?.commit('changeCartInfo', cartInfo)
          }
          let headerCartNum = document.querySelector('.j-header-cart-num');
          if (json.cartlists.cartSumQuantity > 0 && headerCartNum) {
            headerCartNum.classList.remove('mshe-hide');
            headerCartNum.innerHTML = json.cartlists.cartSumQuantity;
          }
          this.changeHeaderNav({
            cart: {
              sum: json.cartlists.cartSumQuantity
            }
          })
        }
      })
    },
    refreshCartTagTips() {
      window._gb_cart_tag_tips_?.refresh?.()
    },
    fetchUseLanguage () {
      schttp({
        method: 'GET',
        url: '/api/common/language/get',
        params: { page: 'user_center' }
      }).then(({ language }) => {
        this.assignRootState({ userCenterLanguage: language })
      })
    },
  }
}
</script>

<style lang="less">
.user-center-height{height: 100%}
.user-container {
  height: 100%;
}
.order-mask-dialog {
  .mb-12 {
    margin-bottom: 0.32rem;
  }
}
</style>
